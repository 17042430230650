.content {
  position: relative;
  width: 100%;
}

.preview {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  & span svg {
    font-size: 20px;
  }
}
