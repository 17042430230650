.versionCardItem {
  width: 100%;

  &:not(:first-child) {
    margin-top: 10px;
  }
}

.textArea {
  min-height: 190px !important;
}

.mockupsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.extraSection {
  align-items: center;
  gap: 10px;
}

.column {
  padding: 10px 0;
}
