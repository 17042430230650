.gallery {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  margin-bottom: 10px;

  & div {
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &[data-count='2'] {
    grid-template-columns: repeat(2, 1fr);
    & div {
      width: 137px;
      height: 137px;
    }
  }

  &[data-count='3'] {
    grid-template-columns: repeat(2, 1fr);

    & > div:first-child {
      grid-column: 1/3;
      width: 285px;
      height: 285px;
    }

    & > div:not(:first-child) {
      grid-column: unset;
      width: 137px;
      height: 137px;
    }
  }

  &[data-count='4'] {
    grid-template-columns: repeat(2, 1fr);

    & > div {
      width: 137px;
      height: 137px;
    }
  }

  &[data-count='5'] {
    grid-template-columns: repeat(6, 1fr);

    & > div:first-child {
      grid-column: 1/4;
      width: 137px;
      height: 137px;
    }
    & > div:nth-child(2) {
      grid-column: 4/7;
      width: 137px;
      height: 137px;
    }
    & > div:nth-child(3) {
      grid-column: 1/3;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(4) {
      grid-column: 3/5;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(5) {
      grid-column: 5/7;
      width: 88px;
      height: 88px;
    }
  }

  &[data-count='6'] {
    grid-template-columns: repeat(3, 1fr);
    & > div {
      width: 88px;
      height: 88px;
    }
  }

  &[data-count='7'] {
    grid-template-columns: repeat(6, 1fr);

    & > div:first-child {
      grid-column: 1/4;
      width: 137px;
      height: 137px;
    }
    & > div:nth-child(2) {
      grid-column: 4/7;
      width: 137px;
      height: 137px;
    }
    & > div:nth-child(3) {
      grid-column: 1/4;
      width: 137px;
      height: 137px;
    }
    & > div:nth-child(4) {
      grid-column: 4/7;
      width: 137px;
      height: 137px;
    }
    & > div:nth-child(5) {
      grid-column: 1/3;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(6) {
      grid-column: 3/5;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(7) {
      grid-column: 5/7;
      width: 88px;
      height: 88px;
    }
  }

  &[data-count='8'] {
    grid-template-columns: repeat(6, 1fr);

    & > div:first-child {
      grid-column: 1/4;
      width: 137px;
      height: 137px;
    }
    & > div:nth-child(2) {
      grid-column: 4/7;
      width: 137px;
      height: 137px;
    }
    & > div:nth-child(3) {
      grid-column: 1/3;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(4) {
      grid-column: 3/5;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(5) {
      grid-column: 5/7;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(6) {
      grid-column: 1/3;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(7) {
      grid-column: 3/5;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(8) {
      grid-column: 5/7;
      width: 88px;
      height: 88px;
    }
  }

  &[data-count='9'] {
    grid-template-columns: repeat(3, 1fr);
    & > div {
      width: 88px;
      height: 88px;
    }
  }

  &[data-count='10'] {
    grid-template-columns: repeat(6, 1fr);

    & > div:first-child {
      grid-column: 1/4;
      width: 137px;
      height: 137px;
    }
    & > div:nth-child(2) {
      grid-column: 4/7;
      width: 137px;
      height: 137px;
    }
    & > div:nth-child(3) {
      grid-column: 1/4;
      width: 137px;
      height: 137px;
    }
    & > div:nth-child(4) {
      grid-column: 4/7;
      width: 137px;
      height: 137px;
    }
    & > div:nth-child(5) {
      grid-column: 1/3;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(6) {
      grid-column: 3/5;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(7) {
      grid-column: 5/7;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(8) {
      grid-column: 1/3;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(9) {
      grid-column: 3/5;
      width: 88px;
      height: 88px;
    }
    & > div:nth-child(10) {
      grid-column: 5/7;
      width: 88px;
      height: 88px;
    }
  }
}

.preview {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  & span svg {
    font-size: 20px;
  }
}
