.root {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px 10px 10px 0;
}

.select {
  width: 100%;
}
