.modalContainer {
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

.spinner {
  min-height: 474px;
  display: flex;
  justify-content: center;
  align-items: center;
}
