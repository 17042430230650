.container[data-type='false'] {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.container[data-type='true'] {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #4096ff;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;

  & .author,
  & .message,
  & .date {
    color: #fff;
  }
}

.avatar {
  flex-shrink: 0;
  margin-right: 10px;
}

.texts {
  width: calc(100% - 42px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    margin-bottom: 8px;
  }
}

.author,
.date {
  font-size: 14px;
  font-weight: 600;
  color: #4096ff;
}

.date {
  font-weight: 400;
  color: #ccc;
}

.message {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #ccc;
}

.hasNew {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: white;
  background-color: #3aa7a6;
  margin-left: 10px;
  flex-shrink: 0;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
