.box > div > div:first-child {
  border: 3px solid #4096ff;
  border-radius: 8px;
  position: relative;

  & div > div {
    border: none !important;
  }

  &::after {
    content: 'Main';
    padding: 2px 7px;
    border-radius: 4px;
    background-color: rgba($color: #000000, $alpha: 0.6);
    font-size: 10px;
    color: white;
    position: absolute;
    top: 5px;
    left: -25px;
  }
}
