.logo {
  margin: 0 auto 20px;
  width: 77px;
  height: 27px;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.upper {
  flex: 1 1 auto;
}

.userSection {
  width: 100%;
  max-width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 5px 14px 10px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin: 16px 0 56px;
}

.userInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 11px;
}

.textWrapper {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.avatar {
  flex-shrink: 0;
}

.email,
.name {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-align: left;
}

.email {
  font-size: 10px;
}

.logoutBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 40px;
}

.logoutText {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.85);
  margin-left: 10px;
}

.version {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}
