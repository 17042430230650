.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 210px);
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.btn {
  margin: 0 0 20px;
  align-self: flex-end;
  & button {
    width: 150px;
  }
}

.removeLink {
  color: #ff0000 !important;
}
