.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-top: 10px;
}

.wrapper {
  width: 100%;
  position: relative;
}

.icon,
.iconError {
  position: absolute;
  top: 15px;
  right: 24px;
}

.iconError {
  & i::before {
    color: #f30004;
  }
}

.errorMessage {
  margin-top: 4px;
  color: #f30004;
}
