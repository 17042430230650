.box {
  display: flex;
  flex-direction: column-reverse;
}

.uploadButton {
  display: flex;
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-top: 8px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  box-sizing: border-box;
  width: 472px;
  cursor: pointer;
}
