.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00b0f0;
  position: relative;
  flex-shrink: 0;
}

.indicatorOnline,
.indicatorOffline {
  position: absolute;
  bottom: 0;
  right: 0;
  background: radial-gradient(circle at 3px 3px, #00d5a6, #00a27e);
  width: 11px;
  height: 11px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-sizing: content-box;
}

.indicatorOffline {
  background: radial-gradient(circle at 3px 3px, #f89f9f, #ec1212);
}
