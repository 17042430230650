.formBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.infoRow {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
