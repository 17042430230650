.iconText,
.iconTextTime,
.iconSaleSource {
  color: rgba(0, 0, 0, 0.45);

  & div article {
    color: rgba(0, 0, 0, 0.45);
  }
}

.iconTextTime {
  width: 180px;
}

.iconCreatedDate {
  width: 245px;
  & div article {
    color: rgba(0, 0, 0, 0.45);
  }
}

.iconModifiedDate {
  width: 280px;
  & div article {
    color: rgba(0, 0, 0, 0.45);
  }
}

.iconSaleSource {
  width: 120px;
}

.listItem {
  min-height: 135px;
}

.listItem > div > ul {
  display: flex;
  align-items: center;
  min-height: 32px;
}

.rowImagesPreview > span {
  margin-top: 32px;
}
