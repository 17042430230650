.versionCard {
  width: 100%;

  &:not(:first-child) {
    margin-top: 10px;
  }
}

.extraSection {
  align-items: center;
  gap: 10px;
}
