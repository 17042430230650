.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chatNameInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 12px 0;
}

.error {
  color: #ff4d4f;
}

.btnGroup {
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 10px;
}

.group {
  width: 100%;
}

.selectedUsers {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.search {
  margin: 12px 0;
}

.userList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.userListItem {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #e6f4ff;
  }
}

.userListItemText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
}

.email {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
