.container {
  position: relative;
}

.listBox,
.chatBox {
  position: fixed;
  bottom: 48px;
  right: 94px;
  width: 300px;
  height: 500px;
  background-color: #fff;
  border: 1px solid #d1dbe3;
  box-sizing: border-box;
  overflow-y: hidden;
  z-index: 99;
}

.chatBox {
  width: 550px;
  right: 404px;
  padding: 0;
}

.titleBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1dbe3;
  padding: 0 12px;
  background-color: #f6fbff;
  box-sizing: border-box;
  min-height: 62px;
}

.title {
  font-weight: bold;
}

.titleIcons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.closeIcon {
  position: absolute;
  top: 24px;
  right: 12px;
  cursor: pointer;
}
