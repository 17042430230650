.artworkCard {
  width: 100%;
  border-color: rgba(5, 5, 5, 0.2);
}

.cardHeader {
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-bottom: 1px solid rgba(5, 5, 5, 0.2) !important;
}

.cardTitle {
  white-space: normal !important;
  font-size: larger;
}

.pannelHead {
  display: flex;
  align-items: center;
  gap: 8px;
}

.row {
  margin-bottom: 12px;
}
