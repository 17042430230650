.card {
  padding: 20px;
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 6px;
  margin-bottom: 15px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.infoItem {
  display: flex;
  margin-bottom: 8px;
}

.label {
  font-weight: 600;
  margin-right: 8px;
}

.value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.space {
  display: flex;
  align-items: center;
  width: 100%;
}

.formBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.getRateBtn {
  align-self: flex-end;
  margin-bottom: 20px;
}

.shippingPriceInput {
  margin-right: 20px;
}
