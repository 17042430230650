.root {
  width: 100%;
}

.thumbnailCard {
  margin-bottom: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  // margin-bottom: 30px;
}
