.container[data-position='left'] {
  min-width: 200px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  border-radius: 5px 5px 0 0;
  margin-top: 20px;
  position: relative;
  background-color: #fff;

  &:before {
    content: '';
    border: 20px solid transparent;
    border-bottom: 20px solid #fff;
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0;
    left: -15px;
  }
}

.container[data-position='right'] {
  min-width: 200px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  border-radius: 5px 5px 0 0;
  margin-top: 20px;
  position: relative;
  z-index: 2;
  background-color: #e6f4ff;
  align-self: flex-end;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: -15px;
    border: 20px solid transparent;
    border-bottom: 20px solid #e6f4ff;
    display: block;
    width: 0;
    height: 0;
  }
}

.author {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #4096ff;
}

.time {
  color: #cccccc;
  align-self: flex-end;
  //   margin-top: -8px;
}

.image {
  margin-bottom: 8px;
  object-fit: cover;
}

.documentPreview {
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.documentInfo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.documentTitle {
  font-weight: 600;
}

.documentSize {
  color: #cccccc;
  margin-top: 3px;
}

.link {
  display: flex;
  word-break: break-all;
  margin-bottom: 8px;
}

.approveBox {
  width: 200px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0 8px;
}
